import { isMobile } from 'pixi.js';
import { Signal } from 'typed-signals';
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class ButtonEvents {
  constructor() {
    __publicField(this, "_isMouseIn");
    __publicField(this, "_isDown");
    /** Event that is fired when the button is down. */
    __publicField(this, "onDown");
    /**
     * Event that fired when a down event happened inside the button
     * and up event happened inside or outside of the button
     */
    __publicField(this, "onUp");
    /**
     * Event that fired when mouse up event happens outside of the button
     * after the down event happened inside the button boundaries.
     */
    __publicField(this, "onUpOut");
    /** Event that fired when the mouse is out of the view */
    __publicField(this, "onOut");
    /** Event that is fired when the button is pressed. */
    __publicField(this, "onPress");
    /** Event that is fired when the mouse hovers the button. Fired only if device is not mobile.*/
    __publicField(this, "onHover");
    this.onPress = new Signal();
    this.onDown = new Signal();
    this.onUp = new Signal();
    this.onHover = new Signal();
    this.onOut = new Signal();
    this.onUpOut = new Signal();
  }
  connectEvents(view) {
    if (isMobile.any) {
      view.on("pointerdown", this.processDown, this);
      view.on("pointerup", this.processUp, this);
      view.on("pointerupoutside", this.processUpOut, this);
      view.on("pointerout", this.processOut, this);
      view.on("pointertap", this.processPress, this);
      view.on("pointerover", this.processOver, this);
    } else {
      view.on("mousedown", this.processDown, this);
      view.on("mouseup", this.processUp, this);
      view.on("mouseupoutside", this.processUpOut, this);
      view.on("mouseout", this.processOut, this);
      view.on("click", this.processPress, this);
      view.on("mouseover", this.processOver, this);
    }
  }
  disconnectEvents(view) {
    if (isMobile.any) {
      view.off("pointerdown", this.processDown, this);
      view.off("pointerup", this.processUp, this);
      view.off("pointerupoutside", this.processUpOut, this);
      view.off("pointerout", this.processOut, this);
      view.off("pointertap", this.processPress, this);
      view.off("pointerover", this.processOver, this);
    } else {
      view.off("mousedown", this.processDown, this);
      view.off("mouseup", this.processUp, this);
      view.off("mouseupoutside", this.processUpOut, this);
      view.off("mouseout", this.processOut, this);
      view.off("click", this.processPress, this);
      view.off("mouseover", this.processOver, this);
    }
  }
  processDown(e) {
    this._isDown = true;
    this.onDown.emit(this, e);
    this.down(e);
  }
  processUp(e) {
    if (this._isDown) {
      this.onUp.emit(this, e);
      this.up(e);
    }
    this._isDown = false;
  }
  processUpOut(e) {
    if (this._isDown) {
      this.onUp.emit(this, e);
      this.onUpOut.emit(this, e);
      this.up(e);
      this.upOut(e);
    }
    this._isDown = false;
  }
  processOut(e) {
    if (this._isMouseIn) {
      this._isMouseIn = false;
      this.onOut.emit(this, e);
      this.out(e);
    }
  }
  processPress(e) {
    this._isDown = false;
    this.onPress.emit(this, e);
    this.press(e);
  }
  processOver(e) {
    if (isMobile.any) return;
    this._isMouseIn = true;
    this.onHover.emit(this, e);
    this.hover(e);
  }
  /**
   * Method called when the button pressed.
   * To be overridden.
   * @param {FederatedPointerEvent} _e - event data
   */
  down(_e) {}
  /**
   * Method called when the button is up.
   * To be overridden.
   * @param {FederatedPointerEvent} _e - event data
   */
  up(_e) {}
  /**
   * Method called when the up event happens outside of the button,
   * after the down event happened inside the button boundaries.
   * To be overridden.
   * @param {FederatedPointerEvent} _e - event data
   */
  upOut(_e) {}
  /**
   * Method called when the mouse leaves the button.
   * To be overridden.
   * @param {FederatedPointerEvent} _e - event data
   */
  out(_e) {}
  /**
   * Method called when the mouse press down the button.
   * To be overridden.
   * @param {FederatedPointerEvent} _e - event data
   */
  press(_e) {}
  /**
   * Method called when the mouse hovers the button.
   * To be overridden.
   * Fired only if device is not mobile.
   * @param {FederatedPointerEvent} _e - event data
   */
  hover(_e) {}
  /** Getter that returns if the button is down. */
  get isDown() {
    return this._isDown;
  }
}
export { ButtonEvents };
