<swiper-container class="mySwiper" slides-per-view="auto" space-between="24" centered-slides="true"
    (swiperslidechange)="onSlideChange($event)">
    @for (character of characters; track $index) {
    <swiper-slide>
        <!-- Character image -->
        <div class="media">
            <video poster="" id="{{character.id}}" playsinline autoplay muted webkit-playsinline
                (ended)="onVideoEnded(character)" oncanplay="this.play()" onloadedmetadata="this.muted = true">
                @if (isSafari) {
                <source
                    src="{{character.characterAnimations.at(nextAnimationIndex(character))?.mediaPath?.replaceAll('.webm', '.mov')}}"
                    type='video/mp4; codecs="hvc1"'>
                }
                @else{
                <source src="{{character.characterAnimations.at(nextAnimationIndex(character))?.mediaPath}}"
                    type="video/webm">
                }
            </video>
        </div>

        <!-- Character name -->
        <div class="name">
            <span>
                {{ character.name }}
            </span>
        </div>

        <div class="info" (click)="onShowInfo(character)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 8.75C10.6904 8.75 11.25 9.30964 11.25 10V14C11.25 14.6904 10.6904 15.25 10 15.25C9.30964 15.25 8.75 14.6904 8.75 14V10C8.75 9.30964 9.30964 8.75 10 8.75ZM10 5C9.30964 5 8.75 5.55964 8.75 6.25C8.75 6.94036 9.30964 7.5 10 7.5C10.6904 7.5 11.25 6.94036 11.25 6.25C11.25 5.55964 10.6904 5 10 5Z"
                    fill="#3ED1D2" />
            </svg>
        </div>

        <!-- Info Panel (SVG icon) -->
        <div class="panel">

        </div>
    </swiper-slide>
    }
</swiper-container>