import { inject, Injectable, InjectionToken } from '@angular/core';
import { UserService } from './user.service';
import { UserData } from '../models/user.model';
import { TelegramWebApp } from '@m1cron-labs/ng-telegram-mini-app';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public currentUser?: UserData;

  public readonly userService = inject(UserService);
  private readonly telegram = inject(TelegramWebApp);

  constructor() {
    this.init();
  }

  async init() {
    console.log('Session init called');

    const id = this.telegram.initDataUnsafe.user?.id ?? 371882322;
    if (id === undefined) return;

    this.userService.search(id).subscribe((response) => {
      if (response != null) {
        this.currentUser = response;
        localStorage.setItem('currentUser', JSON.stringify(response));
      }
    });
  }

  updateUser(user: UserData) {
    this.currentUser = user;
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  get _storedUser(): UserData | undefined {
    var userJson = localStorage.getItem('currentUser');

    if(userJson === null) return undefined;

    return JSON.parse(userJson);
  }

  get user(): UserData | undefined {
    const storedUser = this._storedUser;

    if(storedUser)
      return storedUser;

    if (this.currentUser)
      return this.currentUser;

    return undefined;
  }

  get isSignedIn(): boolean {
    return this.user !== undefined;
  }

  get isRegistered(): boolean {
    return this.user?.isRegistered ?? false;
  }

  get isCharacterSelected(): boolean {
    return this.user?.selectedCharacter != null;
  }
}
