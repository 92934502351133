import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-language-bottom-sheet',
  standalone: true,
  imports: [],
  templateUrl: './language-bottom-sheet.component.html',
  styleUrl: './language-bottom-sheet.component.scss'
})
export class LanguageBottomSheetComponent {
  language: string = 'ru';

  constructor(
    private bottomSheetRef: MatBottomSheetRef<LanguageBottomSheetComponent>,
  ) { }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  onLanguage(language: string) {
    this.language = language;
  }
}
