import { Character } from "./character.model";

export class UserStats {
    constructor(
        public balance: number,
        public wins: number,
        public rating: number,
        public fails: number,
        public coins: number,
        public gamesPlayed: number,
        public earnedTons: number,
        public lastOnlineTimestamp: Date
    ) { }

    static fromMap(map: any): UserStats {
        return new UserStats(
            map.balance || 0,
            map.wins || 0,
            map.rating || 0,
            map.fails || 0,
            map.coins || 0,
            map.gamesPlayed || 0,
            map.earnedTons || 0,
            new Date(map.lastOnlineTimestamp || 0)
        );
    }
}

export class UserData {
    constructor(
        public id: string,
        public telegramId: number,
        public username: string,
        public profileImage: string | null,
        public locale: string | null,
        public isRegistered: boolean,
        public statistic: UserStats,
        public selectedCharacter: Character | null,
    ) { }

    static fromMap(map: any): UserData {
        return new UserData(
            map._id || '',
            map.telegramId || 0,
            map.username || '',
            map.profileImage || '',
            map.locale || null,
            map.isRegistered || false,
            UserStats.fromMap(map.statistic || {}),
            map.selectedCharacter == null ? null: Character.fromMap(map.selectedCharacter),
        );
    }
}
