import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationBarComponent } from "../../components/navigation-bar/navigation-bar.component";

@Component({
  selector: 'app-top-page',
  standalone: true,
  imports: [RouterOutlet, NavigationBarComponent],
  templateUrl: './top-page.component.html',
  styleUrl: './top-page.component.scss'
})
export class TopPageComponent {

}
