import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-bar',
  standalone: true,
  imports: [],
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.scss'
})
export class NavigationBarComponent {
  currentRoute: string;

  constructor(private router: Router) {
    this.currentRoute = router.url;
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }
}
