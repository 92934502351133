import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TonConnectUI } from '@tonconnect/ui';
import { tonConnectUI } from '../../app.component';

@Component({
  selector: 'app-wallet-dialog',
  standalone: true,
  imports: [],
  templateUrl: './wallet-dialog.component.html',
  styleUrl: './wallet-dialog.component.scss'
})
export class WalletDialogComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<WalletDialogComponent>,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) { }

  get address(): string {
    return tonConnectUI.account?.address ?? 'Error';
  }

  copyAddress() {
    this.clipboard.copy(this.address);

    this.snackBar.open('Адрес скопирован', 'ОК', { duration: 900 });
  }

  async disconnectWallet() {
    await tonConnectUI.disconnect();
    
    this.close();
    this.snackBar.open('Кошелёк отвязан', 'ОК', { duration: 900 });
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }
}
