import { Component, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MainButtonComponent } from "../../../../components/main-button/main-button.component";
import { Router } from '@angular/router';
import { Character } from '../../../../models/character.model';

@Component({
  selector: 'app-character-bottom-sheet',
  standalone: true,
  imports: [MainButtonComponent],
  templateUrl: './character-bottom-sheet.component.html',
  styleUrl: './character-bottom-sheet.component.scss'
})
export class CharacterBottomSheetComponent {
  character: Character;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<CharacterBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { character: Character }
  ) { 
    this.character = data.character;
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }
}
