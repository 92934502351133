import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MainButtonComponent } from "../main-button/main-button.component";
import { TonConnectUI } from '@tonconnect/ui';
import { tonConnectUI } from '../../app.component';
import { FormsModule } from '@angular/forms';
import { UserData } from '../../models/user.model';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-withdraw-bottom-sheet',
  standalone: true,
  imports: [MainButtonComponent, FormsModule],
  templateUrl: './withdraw-bottom-sheet.component.html',
  styleUrl: './withdraw-bottom-sheet.component.scss'
})
export class WithdrawBottomSheetComponent {
  amount: number | null = null;
  address: string | null = null;

  get tonConnect(): TonConnectUI {
    return tonConnectUI;
  }

  get currentUser(): UserData | unknown {
    return this.session.user;
  }

  get min(): number {
    return 0.35;
  }

  get max(): number {
    return 500;
  }

  get tons(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.session.user?.statistic.balance ?? 0));
  }

  get coins(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.session.user?.statistic.coins ?? 0));
  }

  constructor(
    private session: SessionService,
    private bottomSheetRef: MatBottomSheetRef<WithdrawBottomSheetComponent>,
  ) {}

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  onConfirm() {
    if (this.amount == null || this.amount < 1) return;
    if (this.address == null || this.address.trim().length < 1) return;
  }
}
