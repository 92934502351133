import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Character } from '../models/character.model';

@Injectable({
  providedIn: 'root'
})
export class CharacterService {
  private baseUrl = 'api.super-ton.org';
  private headers = new HttpHeaders({
    'x-api-key': 'aYsWx3GKpcI2ARFnJlhRwrKDJZrE3QgR', 
    'Access-Control-Allow-Origin': '*',
  });

  constructor(private http: HttpClient) { }

  getCharacters(): Observable<Character[]> {
    return this.http.get<{ data: any[] }>(`https://${this.baseUrl}/api/characters/`, { headers: this.headers })
      .pipe(
        map(response => {
          // Check if response data exists
          if (response.data) {
            return response.data.map(character => Character.fromMap(character));
          }
          return [];
        })
      );
  }
}
