import { Component } from '@angular/core';
import { UserData } from '../../models/user.model';
import { SessionService } from '../../services/session.service';
import { THEME, TonConnectUI } from '@tonconnect/ui';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WalletDialogComponent } from '../wallet-dialog/wallet-dialog.component';
import { tonConnectUI } from '../../app.component';

@Component({
  selector: 'app-connect-wallet',
  standalone: true,
  imports: [],
  templateUrl: './connect-wallet.component.html',
  styleUrl: './connect-wallet.component.scss'
})
export class ConnectWalletComponent {
  currentUser: UserData | undefined;
  unsubscribe: (() => void) | undefined;

  constructor(private session: SessionService, private bottomSheet: MatBottomSheet) {
    this.currentUser = session.user;
  }

  get tonConnect(): TonConnectUI {
    return tonConnectUI;
  }

  async connectWallet() {
    await tonConnectUI.openModal();
  }

  async showWallet() {
    this.bottomSheet.open(
      WalletDialogComponent,
      { panelClass: 'mat-bottom-sheet-container-dark' }
    );
  }
}
