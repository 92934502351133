export class CharacterAnimation {
    constructor(public name: string, public mediaPath: string) { }

    static fromMap(map: any): CharacterAnimation {
        return new CharacterAnimation(map.name || '', map.mediaPath || '');
    }
}

export class Character {
    constructor(
        public id: string,
        public order: number,
        public name: string,
        public description: string,
        public characterAnimations: CharacterAnimation[],
        public gameAnimations: CharacterAnimation[]
    ) { }

    static fromMap(map: any): Character {
        return new Character(
            map._id || '',
            map.order || 0,
            map.name || '',
            map.description || '',
            map.characterAnimations.map((x: any) =>
                CharacterAnimation.fromMap(x)
            ) || [],
            map.gameAnimations.map((x: any) =>
                CharacterAnimation.fromMap(x)
            ) || []
        );
    }
}
