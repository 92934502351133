import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, OnInit, Output } from '@angular/core';
import SwiperCore from 'swiper';
import { CharacterService } from '../../../../services/character.service';
import { Character } from '../../../../models/character.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CharacterBottomSheetComponent } from '../character-bottom-sheet/character-bottom-sheet.component';
import { DeviceDetectorService } from 'ngx-device-detector';

SwiperCore.use([]);

interface PlayedAnimation {
  id: string;
  name: string;
  index: number;
}

@Component({
  selector: 'app-characters-slider',
  standalone: true,
  imports: [],
  templateUrl: './characters-slider.component.html',
  styleUrl: './characters-slider.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CharactersSliderComponent {
  characters: Character[] = [];
  playedAnimations: PlayedAnimation[] = [];

  centeredIndex: number = 0;

  @Output() onCharacterChange = new EventEmitter<Character>();

  constructor(
    private bottomSheet: MatBottomSheet, 
    private charactersService: CharacterService,
    private deviceService: DeviceDetectorService
  ) {
    charactersService.getCharacters().subscribe((response) => {
      if (response != null) {
        this.characters = response;
        this.characters.sort((a, b) => b.order - a.order);

        this.onCharacterChange.emit(this.characters.at(0));

        this.characters.map((character) => {
          const animation: PlayedAnimation = {
            id: character.id,
            name: character.characterAnimations.at(0)?.name ?? "",
            index: 0
          };

          this.playedAnimations.push(animation);
        });
      }
    });
  }

  get isSafari() : boolean {
    if (this.deviceService.os.toLowerCase().includes('ios') || this.deviceService.os.toLowerCase().includes('mac')) {
      return this.deviceService.browser.toLowerCase().includes('safari');
    }

    return false;
  }

  onSlideChange(event: any) {
    this.centeredIndex = event?.detail?.[0]?.activeIndex;

    this.onCharacterChange.emit(this.characters.at(this.centeredIndex));
  }

  onShowInfo(character: Character) { 
    this.bottomSheet.open(
      CharacterBottomSheetComponent,
      { 
        panelClass: 'bottom-sheet-container',
        data: { character: character }
      }
    );
  }

  getNextAnimationIndex(character: Character): number {
    const animation = this.playedAnimations.find((animation) => animation.id === character.id);
    if (animation) {
      const currentIndex = animation.index;
      const nextIndex = (currentIndex + 1) % character.characterAnimations.length;
      return nextIndex;
    }
    return 0;
  }

  onVideoEnded(character: Character) {
    setTimeout(() => {
      const nextIndex = this.getNextAnimationIndex(character);

      const animation = this.playedAnimations.find((animation) => animation.id === character.id);
      if (animation) {
        animation.index = nextIndex;
      }

      const videoElement = document.getElementById(character.id) as HTMLVideoElement;
      if (videoElement) {
        videoElement.load();
        videoElement.play();
      }
    }, 5000);
  }

  nextAnimationIndex(character: Character): number {
    const animation = this.playedAnimations.find((animation) => animation.id === character.id);
    return animation ? animation.index : 0;
  }
}
