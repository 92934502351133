<router-outlet>
    <form [formGroup]="registrationForm" class="registration-page-body" (ngSubmit)="onRegistrationTap()">
        <div class="title">
            ДОБРО
            ПОЖАЛОВАТЬ
            В <span>SUPERTON</span>
        </div>

        <div class="panel">
            <div class="avatar">
                <div class="avatar-image" [style.background-image]="avatarImage ? 'url(' + avatarImage + ')' : ''">
                    <div class="icon" [style.display]="avatarImage ? 'none' : 'flex'">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="white"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M2.67004 18.9501L7.60004 15.6401C8.39004 15.1101 9.53004 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"
                                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <input type="file" id="imageInput" accept="image/*" (change)="onImagePicked($event)" style="display: none;" />

                <div class="image-button" (click)="onPickImage()">
                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.2081 0C9.99833 0 9.78819 0.072271 9.63048 0.21718L8.73068 1.04188L11.3644 3.45507L12.2634 2.63037C12.5789 2.34129 12.5789 1.86034 12.2634 1.57125L10.7857 0.21718C10.6279 0.072271 10.4178 0 10.2081 0ZM8.1643 1.56061L1.65234 7.52939C1.60871 7.56938 1.57589 7.61854 1.55548 7.67283C1.26368 8.47515 0.740934 9.78557 0.514783 10.5354C0.434329 10.8032 0.694903 11.0527 0.990301 10.9903C1.96375 10.7842 3.26662 10.2948 4.1284 10.0317C4.18684 10.0141 4.24088 9.98441 4.28531 9.94515L10.7973 3.97454L8.1643 1.56061Z"
                            fill="#3ED1D2" />
                    </svg>

                    <span>
                        Edit
                    </span>
                </div>
            </div>

            <div class="nickname-wrapper">
                <label for="nickname">Nickname</label>
                <input type="text" name="" id="nickname" formControlName="username">
            </div>

            <div class="button-wrapper">
                <app-main-button [buttonText]="'ВОЙТИ'" (buttonClick)="onRegistrationTap()">>
                </app-main-button>
            </div>

            <div class="logo-wrapper">
                <app-logo></app-logo>
            </div>
        </div>
    </form>
</router-outlet>