<router-outlet>
    <div class="content-wrapper">
        <div class="header">
            <app-user-info-card></app-user-info-card>
            <app-connect-wallet></app-connect-wallet>
        </div>

        <div class="character">
            <div class="panel">
                <div class="media">
                    <video poster="" id="{{character?.id}}" playsinline muted autoplay webkit-playsinline (ended)="onVideoEnded()" oncanplay="this.play()" onloadedmetadata="this.muted = true">
                        @if (isSafari) {
                        <source
                            src="{{character?.characterAnimations?.at(nextAnimationIndex())?.mediaPath?.replaceAll('.webm', '.mov')}}" type='video/mp4; codecs="hvc1"'>
                        }
                        @else{
                        <source src="{{character?.characterAnimations?.at(nextAnimationIndex())?.mediaPath}}" type="video/webm">
                        }
                    </video>
                </div>
                <div class="info" (click)="onShowInfo()">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 8.75C10.6904 8.75 11.25 9.30964 11.25 10V14C11.25 14.6904 10.6904 15.25 10 15.25C9.30964 15.25 8.75 14.6904 8.75 14V10C8.75 9.30964 9.30964 8.75 10 8.75ZM10 5C9.30964 5 8.75 5.55964 8.75 6.25C8.75 6.94036 9.30964 7.5 10 7.5C10.6904 7.5 11.25 6.94036 11.25 6.25C11.25 5.55964 10.6904 5 10 5Z"
                            fill="#3ED1D2" />
                    </svg>
                </div>
            </div>
        </div>

        <div class="statistic">
            <div class="item">
                <div class="top">
                    <div class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.85714 3C6.38376 3 6 3.40294 6 3.9V6.6C6 9.77382 8.23518 12.3995 11.1429 12.8362V15.6907C9.2052 16.1057 7.71429 17.8976 7.71429 20.0644C7.71429 20.5811 8.11325 21 8.60538 21H15.3946C15.8868 21 16.2857 20.5811 16.2857 20.0644C16.2857 17.8976 14.7948 16.1057 12.8571 15.6907V12.8362C15.7648 12.3995 18 9.77382 18 6.6V3.9C18 3.40294 17.6162 3 17.1429 3H6.85714ZM16.2857 6.6C16.2857 9.08528 14.3669 11.1 12 11.1C9.63307 11.1 7.71429 9.08528 7.71429 6.6V4.8H16.2857V6.6ZM12 17.4C10.8795 17.4 9.91862 18.1579 9.56919 19.2H14.4308C14.0814 18.1579 13.1205 17.4 12 17.4ZM12.9857 6.15C12.9857 5.85128 12.8291 5.5768 12.5783 5.43584C12.3275 5.29489 12.0231 5.31034 11.7864 5.47604L10.5007 6.37604C10.1462 6.62418 10.0504 7.12709 10.2867 7.49931C10.523 7.87153 11.002 7.97211 11.3565 7.72396L11.4429 7.6635V9.75C11.4429 10.1974 11.7882 10.56 12.2143 10.56C12.6403 10.56 12.9857 10.1974 12.9857 9.75V6.15Z"
                                fill="#3ED1D2" />
                        </svg>
                    </div>
                    <div class="value">
                        {{wins}}
                    </div>
                </div>
                <div class="name">
                    WIN
                </div>
            </div>

            <div class="item">
                <div class="top">
                    <div class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.4 3C16.4059 3 15.6 3.80589 15.6 4.8V19.2C15.6 20.1941 16.4059 21 17.4 21H19.2C20.1941 21 21 20.1941 21 19.2V4.8C21 3.80589 20.1941 3 19.2 3H17.4ZM17.4 4.8L19.2 4.8V19.2H17.4V4.8ZM9.3 10.2C9.3 9.20589 10.1059 8.4 11.1 8.4H12.9C13.8941 8.4 14.7 9.20589 14.7 10.2V19.2C14.7 20.1941 13.8941 21 12.9 21H11.1C10.1059 21 9.3 20.1941 9.3 19.2V10.2ZM12.9 10.2H11.1V19.2H12.9V10.2ZM3 13.8C3 12.8059 3.80589 12 4.8 12H6.6C7.59411 12 8.4 12.8059 8.4 13.8V19.2C8.4 20.1941 7.59411 21 6.6 21H4.8C3.80589 21 3 20.1941 3 19.2V13.8ZM6.6 13.8H4.8V19.2H6.6V13.8Z"
                                fill="#3ED1D2" />
                        </svg>
                    </div>
                    <div class="value">
                        {{rating}}
                    </div>
                </div>
                <div class="name">
                    ВАШ РЕЙТИНГ
                </div>
            </div>

            <div class="item">
                <div class="top">
                    <div class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.0566 21C11.6147 21 10.4881 19.8448 10.4153 18.4667C10.3838 17.869 10.3321 17.2309 10.2507 16.732C10.2209 16.5491 10.1222 16.2761 9.94269 15.9297C9.76947 15.5954 9.54938 15.2462 9.32551 14.922C9.10304 14.5997 8.88561 14.3147 8.72344 14.11C8.69691 14.0765 8.67194 14.0453 8.64876 14.0165L7.21669 13.6001C6.88134 13.8512 6.46448 14 6.01274 14H4.00637C2.89828 14 2 13.1046 2 12V5C2 3.89543 2.89828 3 4.00637 3H6.01274C6.6252 3 7.17357 3.27355 7.54159 3.70477L7.54204 3.70455C8.49874 3.24091 9.54862 3 10.6124 3H18.2346C19.7283 3 20.996 4.09215 21.2124 5.56545L21.9677 10.7083C22.2336 12.5188 20.8255 14.1429 18.9899 14.1429H16.7198V17.3485C16.7198 19.3651 15.0798 21 13.0566 21ZM8.01912 5.69718L8.41928 5.50325C9.10265 5.17208 9.85256 5 10.6124 5H18.2346C18.7325 5 19.155 5.36405 19.2272 5.85515L19.9825 10.998C20.0711 11.6015 19.6017 12.1429 18.9899 12.1429H15.7166C15.1626 12.1429 14.7135 12.5906 14.7135 13.1429V17.3485C14.7135 18.2606 13.9717 19 13.0566 19C12.7389 19 12.4387 18.7352 12.4189 18.3616C12.3862 17.7414 12.3301 17.0178 12.2311 16.4109C12.1517 15.9243 11.944 15.4339 11.7253 15.012C11.5004 14.5779 11.2307 14.1536 10.9783 13.7881C10.7246 13.4206 10.4794 13.0995 10.2981 12.8706C10.2071 12.7557 10.1314 12.6631 10.0778 12.5983C10.0509 12.5659 10.0295 12.5404 10.0144 12.5225L9.99652 12.5015L9.99132 12.4954L9.98968 12.4935L9.9891 12.4928L9.98888 12.4925C9.98878 12.4924 9.98869 12.4923 9.2268 13.1429L9.98869 12.4923C9.86192 12.3448 9.69479 12.2373 9.50772 12.1829L8.01912 11.75V5.69718ZM6.01274 12H4.00637V10V7V5H6.01274V5.07143V7V10V12Z"
                                fill="#3ED1D2" />
                        </svg>
                    </div>
                    <div class="value">
                        {{fails}}
                    </div>
                </div>
                <div class="name">
                    FAIL
                </div>
            </div>
        </div>

        <div class="button-wrapper">
            <app-main-button [buttonText]="'ИГРАТЬ'" (buttonClick)="onPlay()"></app-main-button>
        </div>

        <app-navigation-bar></app-navigation-bar>
    </div>
</router-outlet>