"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollectorWhile0 = void 0;
const Collector_1 = require("./Collector");
/**
 * Keep signal emissions going while all handlers return false.
 *
 * @typeparam THandler The function signature to be implemented by handlers.
 */
class CollectorWhile0 extends Collector_1.Collector {
  constructor() {
    super(...arguments);
    this.result = false;
  }
  handleResult(result) {
    this.result = result;
    return !this.result;
  }
  /**
   * Get the result of the last signal handler.
   */
  getResult() {
    return this.result;
  }
  /**
   * Reset the result
   */
  reset() {
    this.result = false;
  }
}
exports.CollectorWhile0 = CollectorWhile0;