<div class="sheet-wrapper">
    <div class="header">
        <div class="close-button" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L11.4142 10L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L10 11.4142L7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929L8.58579 10L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L10 8.58579L12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289Z"
                    fill="#3ED1D2" />
            </svg>
        </div>
    </div>

    <div class="sheet-content">
        <div class="image">
            <img src="assets/images/coins-image.png" alt="">
        </div>

        <div class="title">
            Ваш кошелек TON подключен
        </div>

        <div class="subtitle">
            Вы можете отключить его или скопировать адрес кошелька
        </div>

        <div class="wallet">
            <div class="close" (click)="disconnectWallet()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292894 0.292893C-0.0976295 0.683417 -0.0976295 1.31658 0.292894 1.70711L7.58579 9L0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683418 18.0976 1.31658 18.0976 1.70711 17.7071L9 10.4142L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L10.4142 9L17.7071 1.70711Z"
                        fill="#929292" />
                </svg>
            </div>

            <div class="address" (click)="copyAddress()">
                <div class="details">
                    {{address}}
                </div>
                <div class="copy">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 12C4 13.6569 5.34315 15 7 15L9 15C9.55228 15 10 15.4477 10 16C10 16.5523 9.55228 17 9 17L7 17C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7L9 7C9.55228 7 10 7.44771 10 8C10 8.55228 9.55228 9 9 9L7 9C5.34315 9 4 10.3431 4 12ZM20 12C20 13.6569 18.6569 15 17 15L15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17L17 17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7L15 7C14.4477 7 14 7.44771 14 8C14 8.55228 14.4477 9 15 9L17 9C18.6569 9 20 10.3431 20 12ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13L16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11L8 11Z"
                            fill="#929292" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>