import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationBarComponent } from "../../components/navigation-bar/navigation-bar.component";

@Component({
  selector: 'app-referral-page',
  standalone: true,
  imports: [RouterOutlet, NavigationBarComponent],
  templateUrl: './referral-page.component.html',
  styleUrl: './referral-page.component.scss'
})
export class ReferralPageComponent {

}
