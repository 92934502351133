import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SingleGameComponent } from '../../components/single-game/single-game.component';

@Component({
  selector: 'app-single-game-page',
  standalone: true,
  imports: [RouterOutlet, SingleGameComponent],
  templateUrl: './single-game-page.component.html',
  styleUrl: './single-game-page.component.scss'
})
export class SingleGamePageComponent {
  
}
