<div class="logo">
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 15.1579C0 6.7864 6.78641 0 15.1579 0H42.7987C51.1701 0 57.9565 6.78641 57.9565 15.1579V28.9783C57.9565 44.9825 44.9825 57.9565 28.9783 57.9565C12.974 57.9565 0 44.9825 0 28.9783V15.1579Z"
            fill="#3FD4D4" />
        <path
            d="M29.6342 31.2389C29.6342 32.0243 29.5322 32.7332 29.3282 33.3655C29.1344 33.9877 28.8743 34.5385 28.5479 35.0179C28.2215 35.4973 27.839 35.9053 27.4004 36.2419C26.9619 36.5784 26.508 36.8538 26.0388 37.068C25.5696 37.2822 25.0902 37.4403 24.6006 37.5423C24.1213 37.6443 23.6674 37.6953 23.239 37.6953H7.89356V33.7174H23.239C24.004 33.7174 24.5955 33.493 25.0137 33.0443C25.4421 32.5955 25.6563 31.9937 25.6563 31.2389C25.6563 30.8717 25.6002 30.5351 25.488 30.2291C25.3758 29.9232 25.2126 29.658 24.9984 29.4336C24.7944 29.2092 24.5394 29.0358 24.2334 28.9134C23.9377 28.791 23.6062 28.7298 23.239 28.7298H14.0899C13.4473 28.7298 12.7537 28.6176 12.0091 28.3932C11.2646 28.1586 10.571 27.7863 9.9284 27.2763C9.29602 26.7664 8.76563 26.0983 8.33725 25.2721C7.91906 24.4459 7.70996 23.4362 7.70996 22.2428C7.70996 21.0494 7.91906 20.0448 8.33725 19.2288C8.76563 18.4026 9.29602 17.7345 9.9284 17.2246C10.571 16.7044 11.2646 16.3321 12.0091 16.1077C12.7537 15.8731 13.4473 15.7558 14.0899 15.7558H27.6299V19.7337H14.0899C13.3351 19.7337 12.7435 19.9632 12.3151 20.4222C11.8969 20.8811 11.6878 21.488 11.6878 22.2428C11.6878 23.0078 11.8969 23.6147 12.3151 24.0634C12.7435 24.502 13.3351 24.7213 14.0899 24.7213H23.239H23.2696C23.698 24.7315 24.1519 24.7927 24.6312 24.9049C25.1106 25.0069 25.5849 25.1701 26.0541 25.3945C26.5335 25.6189 26.9874 25.9045 27.4157 26.2513C27.8441 26.5879 28.2215 26.9958 28.5479 27.4752C28.8845 27.9546 29.1497 28.5054 29.3435 29.1276C29.5373 29.7498 29.6342 30.4535 29.6342 31.2389ZM52.5223 19.7337H43.7556V37.6953H39.7778V19.7337H30.9958V15.7558H52.5223V19.7337Z"
            fill="white" />
    </svg>
    <div class="logo-text">
        Super Ton
    </div>
</div>
