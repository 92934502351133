import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NavigationBarComponent } from "../../components/navigation-bar/navigation-bar.component";
import { SessionService } from '../../services/session.service';
import { UserData } from '../../models/user.model';
import { UserInfoCardComponent } from "../../components/user-info-card/user-info-card.component";
import { ConnectWalletComponent } from "../../components/connect-wallet/connect-wallet.component";
import { MainButtonComponent } from "../../components/main-button/main-button.component";
import { Character } from '../../models/character.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CharacterBottomSheetComponent } from '../characters-page/components/character-bottom-sheet/character-bottom-sheet.component';
import { DeviceDetectorService } from 'ngx-device-detector';

interface PlayedAnimation {
  id: string;
  name: string;
  index: number;
}

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [RouterOutlet, NavigationBarComponent, UserInfoCardComponent, ConnectWalletComponent, MainButtonComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {
  playedAnimations: PlayedAnimation | undefined;

  get currentUser(): UserData | undefined {
    return this.session.user;
  }

  get wins(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.currentUser?.statistic.wins ?? 0));
  }

  get rating(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.currentUser?.statistic.rating ?? 0));
  }

  get fails(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.currentUser?.statistic.fails ?? 0));
  }

  get character(): Character | null | undefined {
    return this.session.user?.selectedCharacter;
  }

  get isSafari(): boolean {
    if(this.deviceService.os.toLowerCase().includes('ios') || this.deviceService.os.toLowerCase().includes('mac')) {
      return this.deviceService.browser.toLowerCase().includes('safari');
    }

    return false;
  }

  constructor(
    private session: SessionService, 
    private bottomSheet: MatBottomSheet, 
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {
    if (this.character) {
      this.playedAnimations = {
        id: this.character.id,
        name: this.character.characterAnimations.at(0)?.name ?? "",
        index: 0
      };
    }
  }

  onPlay() {
    this.router.navigateByUrl('/single-play');
  }

  onShowInfo() {
    this.bottomSheet.open(
      CharacterBottomSheetComponent,
      {
        panelClass: 'mat-bottom-sheet-container-dark',
        data: { character: this.character }
      }
    );
  }

  getNextAnimationIndex(): number {
    if (this.playedAnimations && this.character) {
      const currentIndex = this.playedAnimations.index;
      const nextIndex = (currentIndex + 1) % this.character.characterAnimations.length;
      return nextIndex;
    }

    return 0;
  }

  onVideoEnded() {
    setTimeout(() => {
      const nextIndex = this.getNextAnimationIndex();

      if (this.playedAnimations) {
        this.playedAnimations.index = nextIndex;
      }

      if (this.character) {
        const videoElement = document.getElementById(this.character.id) as HTMLVideoElement;
        if (videoElement) {
          videoElement.load();
          videoElement.play();
        }
      }
    }, 5000);
  }

  nextAnimationIndex(): number {
    return this.playedAnimations ? this.playedAnimations.index : 0;
  }
}
