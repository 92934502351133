<router-outlet>
    <div class="content-wrapper">
        <div class="header">
            <app-user-info-card [showAvatar]="false"></app-user-info-card>
        </div>

        <div class="user">
            <div class="avatar">
                <img src="https://api.super-ton.org/{{currentUser?.profileImage}}" alt="">
            </div>
            <div class="name">
                <div class="settings" (click)="onSettings()">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.81692 1.13738L7.20125 1.91855H5.17228C3.52338 1.91855 2.18668 3.27537 2.18668 4.9491V6.89837L1.32949 7.47844C-0.443162 8.67799 -0.443164 11.322 1.32949 12.5216L2.18668 13.1016V15.0509C2.18668 16.7246 3.52338 18.0814 5.17228 18.0814H7.20125L7.81692 18.8626C9.01214 20.3791 11.2844 20.3791 12.4797 18.8626L13.0953 18.0814H15.1243C16.7732 18.0814 18.1099 16.7246 18.1099 15.0509V12.9914L18.8795 12.3665C20.3735 11.1533 20.3735 8.84674 18.8795 7.63354L18.1099 7.0086V4.9491C18.1099 3.27537 16.7732 1.91855 15.1243 1.91855H13.0953L12.4797 1.13738C11.2844 -0.379127 9.01213 -0.379124 7.81692 1.13738ZM7.67957 3.93892C7.98189 3.93892 8.26783 3.79942 8.45669 3.55979L9.37117 2.39949C9.76957 1.89399 10.527 1.89399 10.9254 2.39949L11.8399 3.55979C12.0287 3.79942 12.3147 3.93892 12.617 3.93892H15.1243C15.6739 3.93892 16.1195 4.39119 16.1195 4.9491V7.49412C16.1195 7.801 16.2569 8.09124 16.493 8.28294L17.6361 9.21118C18.1341 9.61558 18.1341 10.3844 17.6361 10.7888L16.493 11.7171C16.2569 11.9088 16.1195 12.199 16.1195 12.5059V15.0509C16.1195 15.6088 15.6739 16.0611 15.1243 16.0611H12.617C12.3147 16.0611 12.0287 16.2006 11.8399 16.4402L10.9254 17.6005C10.527 18.106 9.76957 18.106 9.37117 17.6005L8.45669 16.4402C8.26783 16.2006 7.98189 16.0611 7.67957 16.0611H5.17228C4.62265 16.0611 4.17708 15.6088 4.17708 15.0509V12.561C4.17708 12.2232 4.01078 11.9078 3.73392 11.7205L2.43356 10.8405C1.84268 10.4407 1.84268 9.55933 2.43356 9.15948L3.73392 8.27953C4.01078 8.09217 4.17708 7.77676 4.17708 7.43901V4.9491C4.17708 4.39119 4.62265 3.93892 5.17228 3.93892H7.67957ZM10 9C10.5523 9 11 9.44772 11 10C11 10.5523 10.5523 11 10 11C9.44771 11 9 10.5523 9 10C9 9.44772 9.44771 9 10 9ZM7 10C7 11.6569 8.34315 13 10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10Z"
                            fill="#525252" />
                    </svg>
                </div>
                <div class="username">
                    {{username}}
                </div>
            </div>
        </div>

        <div class="balance">
            <div class="current-balance">
                <div class="value">
                    {{currentUser?.statistic?.balance}} <span>TON</span>
                </div>
                <div class="title">
                    Баланс
                </div>
            </div>

            <div class="buttons-wrapper">
                @if(!tonConnect.connected) {
                <div class="ton-connect" (click)="connectWallet()">
                    CONNECT
                </div>
                }
                @else {
                <div class="withdraw" (click)="onWithdraw()">
                    <div class="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M19 7.5C19 6.00317 18.0359 4.12496 16.5 4L15.6224 1.93532C15.0695 0.394627 13.2812 -0.395792 11.6908 0.197587L2.13569 3.76269C2.04025 3.7983 1.95378 3.84635 1.87753 3.90413C0.776754 4.32788 0 5.79858 0 7V17C0 18.5798 1.34315 20 3 20C3 20 14.3431 20 16 20C17.6569 20 19 18.5798 19 17L19 15C19.5978 14.6703 20 13.7059 20 13V11C20 10.2941 19.5978 9.32974 19 9V7.5ZM17 9V7C17 6.47339 16.5523 6 16 6H3C2.44772 6 2 6.47338 2 7L2 17C2 17.5266 2.44772 18 3 18H16C16.5523 18 17 17.5266 17 17V15H16C14.3431 15 13 13.5798 13 12C13 10.4202 14.3431 9 16 9H17ZM13.7301 2.55276L14.4437 4H7.5L12.4196 1.97351C12.9497 1.77572 13.5458 2.03919 13.7301 2.55276ZM15 12C15 11.4734 15.4477 11 16 11H18V13H16C15.4477 13 15 12.5266 15 12Z"
                                fill="#3ED1D2" />
                        </svg>
                    </div>
                    <div class="title">
                        ВЫВЕСТИ
                    </div>
                </div>
                <div class="deposit" (click)="onDeposit()">
                    <div class="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.94165 5.50035C11.0802 2.97284 14.3955 1.7958 17.6234 2.37668C18.2043 5.60469 17.0273 8.92014 14.4999 11.0588L10.705 14.2699L9.82652 14.7891L5.21055 10.1729L5.72553 9.30138L8.94165 5.50035ZM4.06031 7.81597L7.2388 4.05941C7.26336 4.03039 7.28804 4.00151 7.31285 3.97277C6.00944 4.26295 4.28509 5.00839 2.27017 6.75812L4.06031 7.81597ZM10.0123 1.68205C12.5925 0.105703 15.7412 -0.41466 18.7705 0.34269C19.2072 0.451865 19.5482 0.79284 19.6573 1.22955C20.4148 4.25949 19.8941 7.40893 18.3172 9.9895C18.4073 10.5074 18.4619 11.1879 18.3874 12.0048C18.2156 13.8887 17.3668 16.4065 14.8622 19.2646C13.8455 20.4248 12.0752 20.1419 11.3465 18.9086L10.4278 17.3538C9.94375 17.565 9.36396 17.472 8.96928 17.0864L9.09652 17.2137L8.30786 18.0024C7.43673 18.8735 6.02435 18.8735 5.15322 18.0024L1.99859 14.8476C1.12746 13.9765 1.12746 12.564 1.99859 11.6929L2.78725 10.9042L2.91428 11.0312C2.52859 10.6363 2.43565 10.0562 2.64714 9.57199L1.0914 8.65265C-0.141874 7.92387 -0.42479 6.15353 0.735371 5.1368C3.59339 2.63212 6.11104 1.78322 7.99491 1.61146C8.81291 1.53688 9.49415 1.59165 10.0123 1.68205ZM4.3645 12.4816L3.57591 13.2702L6.73054 16.425L7.51913 15.6364L4.3645 12.4816ZM12.1839 15.9408L13.2409 17.7297C14.9896 15.7159 15.7351 13.9924 16.0257 12.6892C15.9975 12.7135 15.9692 12.7377 15.9408 12.7617L12.1839 15.9408Z"
                                fill="#3ED1D2" />
                        </svg>
                    </div>
                    <div class="title">
                        ПОПОЛНИТЬ
                    </div>
                </div>
                }
            </div>
        </div>

        <div class="stats">
            <div class="played">
                <span>Игр сыграно:</span> {{gamesPlayed}}
            </div>
            <div class="earned">
                <span>Заработано:</span> {{earnedTons}}
            </div>
        </div>

        <app-navigation-bar></app-navigation-bar>
    </div>
</router-outlet>