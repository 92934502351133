import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { TelegramWebApp } from '@m1cron-labs/ng-telegram-mini-app';
import TonConnect from '@tonconnect/sdk';
import { THEME, TonConnectUI } from '@tonconnect/ui';

export const tonConnectUI = new TonConnectUI({
  manifestUrl: 'https://super-ton.org/tonconnect-manifest.json',
});
tonConnectUI.uiOptions = {
  language: 'ru',
  uiPreferences: {
    theme: THEME.DARK
  },
  actionsConfiguration: {
    twaReturnUrl: 'https://t.me/SuperTonDev_bot', 
    modals: ['before', 'success', 'error'],
    notifications: ['before', 'success', 'error'],
  }
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'super-ton-app';

  private readonly telegram = inject(TelegramWebApp);

  constructor() {
    this.telegram.ready();
    this.telegram.expand();
    this.telegram.disableVerticalSwipes();

    const connector = new TonConnect();

    connector.restoreConnection();
  }

  ngOnInit() {
    console.debug('Telegram Web App is ready', this.telegram.initDataUnsafe);
  }

  ngOnDestroy(): void {
    this.telegram.close();
  }
}
