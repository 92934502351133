import { Component, Input } from '@angular/core';
import { UserData } from '../../models/user.model';
import { SessionService } from '../../services/session.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CoinInfoDialogComponent } from '../coin-info-dialog/coin-info-dialog.component';

@Component({
  selector: 'app-user-info-card',
  standalone: true,
  imports: [],
  templateUrl: './user-info-card.component.html',
  styleUrl: './user-info-card.component.scss'
})
export class UserInfoCardComponent {
  @Input() showAvatar: boolean = true;

  get currentUser(): UserData | undefined {
    return this.session.user;
  }

  get coins(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.currentUser?.statistic.coins ?? 0));
  }

  constructor(private session: SessionService, private bottomSheet: MatBottomSheet) {}

  onInfoTap() {
    this.bottomSheet.open(
      CoinInfoDialogComponent,
      { panelClass: 'mat-bottom-sheet-container-dark' }
    );
  }
}
