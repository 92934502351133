import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MainButtonComponent } from "../../components/main-button/main-button.component";
import { CharactersSliderComponent } from "./components/characters-slider/characters-slider.component";
import { Character } from '../../models/character.model';
import { SessionService } from '../../services/session.service';
import { UserData } from '../../models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-characters-page',
  standalone: true,
  imports: [RouterOutlet, MainButtonComponent, CharactersSliderComponent],
  templateUrl: './characters-page.component.html',
  styleUrl: './characters-page.component.scss'
})
export class CharactersPageComponent {
  character: Character | undefined;

  constructor(
    private router: Router,
    private session: SessionService,
    private snackBar: MatSnackBar
  ) { }

  onCharacterChange(character: Character) {
    this.character = character;
  }

  onSelectTap() {
    if(this.character === undefined) return;

    const telegramId = this.session.user?.telegramId!;
    const characterId = this.character?.id;

    this.session.userService
      .selectCharacter(telegramId, characterId)
      .subscribe(
        (userData: UserData | null) => {
          if (userData) {
            this.session.updateUser(userData);
            setTimeout(() => {
              this.router.navigateByUrl('/home');
            }, 1000);
          } else {
            this.snackBar.open('Character selection failed. Please try again.', 'Close', {
              duration: 3000
            });
          }
        },
        (error) => {
          // Handle server or network error
          this.snackBar.open('An error occurred. Please try again later.', 'Close', {
            duration: 3000
          });
        }
      );
  }
}
