import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LogoComponent } from "../../components/logo/logo.component";
import { MainButtonComponent } from "../../components/main-button/main-button.component";
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WelcomeBottomSheetComponent } from './components/welcome-bottom-sheet/welcome-bottom-sheet.component';

@Component({
  selector: 'app-welcome-page',
  standalone: true,
  imports: [RouterOutlet, LogoComponent, MainButtonComponent],
  templateUrl: './welcome-page.component.html',
  styleUrl: './welcome-page.component.scss'
})
export class WelcomePageComponent {
  constructor(private bottomSheet: MatBottomSheet) { }

  onRegistrationTap() : void {
    this.bottomSheet.open(
      WelcomeBottomSheetComponent,
      { panelClass: 'bottom-sheet-container' }
    );
  }
}
