import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationBarComponent } from "../../components/navigation-bar/navigation-bar.component";
import { UserInfoCardComponent } from "../../components/user-info-card/user-info-card.component";
import { UserData } from '../../models/user.model';
import { SessionService } from '../../services/session.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { THEME, TonConnectUI } from '@tonconnect/ui';
import { tonConnectUI } from '../../app.component';
import { SettingsBottomSheetComponent } from './components/settings-bottom-sheet/settings-bottom-sheet.component';
import { DepositBottomSheetComponent } from '../../components/deposit-bottom-sheet/deposit-bottom-sheet.component';
import { WithdrawBottomSheetComponent } from '../../components/withdraw-bottom-sheet/withdraw-bottom-sheet.component';

@Component({
  selector: 'app-profile-page',
  standalone: true,
  imports: [RouterOutlet, NavigationBarComponent, UserInfoCardComponent],
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss'
})
export class ProfilePageComponent {
  get tonConnect(): TonConnectUI {
    return tonConnectUI;
  }

  get currentUser(): UserData | undefined {
    return this.session.user;
  }

  get gamesPlayed(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.currentUser?.statistic.gamesPlayed ?? 0));
  }

  get earnedTons(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.currentUser?.statistic.earnedTons ?? 0));
  }

  get username(): string {
    return this.session.user?.username ?? '';
  }

  constructor(private session: SessionService, private bottomSheet: MatBottomSheet) { }

  async connectWallet() {
    await tonConnectUI.openModal();
  }

  onSettings() {
    this.bottomSheet.open(
      SettingsBottomSheetComponent,
      { panelClass: 'mat-bottom-sheet-container-dark' }
    );
  }

  onDeposit() {
    this.bottomSheet.open(
      DepositBottomSheetComponent,
      { panelClass: 'mat-bottom-sheet-container-dark' }
    );
  }

  onWithdraw() {
    this.bottomSheet.open(
      WithdrawBottomSheetComponent,
      { panelClass: 'mat-bottom-sheet-container-dark' }
    );
  }
}
