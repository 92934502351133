import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LogoComponent } from "../logo/logo.component";

@Component({
  selector: 'app-coin-info-dialog',
  standalone: true,
  imports: [LogoComponent],
  templateUrl: './coin-info-dialog.component.html',
  styleUrl: './coin-info-dialog.component.scss'
})
export class CoinInfoDialogComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<CoinInfoDialogComponent>) { }

  close(): void {
    this.bottomSheetRef.dismiss();
  }
}
