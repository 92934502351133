<router-outlet>
   <div class="characters-page-body">
        <div class="title">
            ВЫБЕРИТЕ
            <span>SUPERTON</span>
            ПЕРСОНАЖА
        </div>

        <app-characters-slider (onCharacterChange)="onCharacterChange($event)"></app-characters-slider>

        <div class="button-wrapper">
            <app-main-button [buttonText]="'ВЫБРАТЬ'" (buttonClick)="onSelectTap()">>
            </app-main-button>
        </div>
   </div>
</router-outlet>