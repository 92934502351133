<div class="sheet-wrapper">
    <div class="header">
        <div class="close-button" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L11.4142 10L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L10 11.4142L7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929L8.58579 10L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L10 8.58579L12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289Z"
                    fill="#3ED1D2" />
            </svg>
        </div>
    </div>

    <div class="sheet-content">
        <div class="ballance">
            <div class="ton">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                        <path
                            d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                            fill="#0098EA" />
                        <path
                            d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="name">{{tons}} TON</div>
            </div>
            <div class="super-ton">
                <div class="icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 5.83838C0 2.61393 2.61393 0 5.83838 0H11.1616C14.3861 0 17 2.61393 17 5.83838V8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5V5.83838Z"
                            fill="#3FD4D4" />
                        <path
                            d="M8.46582 9.93945C8.46582 10.1901 8.43327 10.4163 8.36816 10.6182C8.30632 10.8167 8.22331 10.9925 8.11914 11.1455C8.01497 11.2985 7.8929 11.4287 7.75293 11.5361C7.61296 11.6436 7.4681 11.7314 7.31836 11.7998C7.16862 11.8682 7.01562 11.9186 6.85938 11.9512C6.70638 11.9837 6.56152 12 6.4248 12H1.52734V10.7305H6.4248C6.66895 10.7305 6.85775 10.6589 6.99121 10.5156C7.12793 10.3724 7.19629 10.1803 7.19629 9.93945C7.19629 9.82227 7.17839 9.71484 7.14258 9.61719C7.10677 9.51953 7.05469 9.4349 6.98633 9.36328C6.92122 9.29167 6.83984 9.23633 6.74219 9.19727C6.64779 9.1582 6.54199 9.13867 6.4248 9.13867H3.50488C3.2998 9.13867 3.07845 9.10286 2.84082 9.03125C2.60319 8.95638 2.38184 8.83757 2.17676 8.6748C1.97493 8.51204 1.80566 8.29883 1.66895 8.03516C1.53548 7.77148 1.46875 7.44922 1.46875 7.06836C1.46875 6.6875 1.53548 6.36686 1.66895 6.10645C1.80566 5.84277 1.97493 5.62956 2.17676 5.4668C2.38184 5.30078 2.60319 5.18197 2.84082 5.11035C3.07845 5.03548 3.2998 4.99805 3.50488 4.99805H7.82617V6.26758H3.50488C3.264 6.26758 3.0752 6.34082 2.93848 6.4873C2.80501 6.63379 2.73828 6.82747 2.73828 7.06836C2.73828 7.3125 2.80501 7.50618 2.93848 7.64941C3.0752 7.78939 3.264 7.85938 3.50488 7.85938H6.4248H6.43457C6.57129 7.86263 6.71615 7.88216 6.86914 7.91797C7.02214 7.95052 7.1735 8.0026 7.32324 8.07422C7.47624 8.14583 7.62109 8.23698 7.75781 8.34766C7.89453 8.45508 8.01497 8.58529 8.11914 8.73828C8.22656 8.89128 8.3112 9.06706 8.37305 9.26562C8.4349 9.46419 8.46582 9.6888 8.46582 9.93945ZM15.7705 6.26758H12.9727V12H11.7031V6.26758H8.90039V4.99805H15.7705V6.26758Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="name">{{coins}} ST</div>
            </div>
        </div>

        <div class="title">Укажите ваш личный TON адрес</div>
        <div class="subtitle" style="color: #929292; margin-bottom: 8px;">Эта сумма будет отправлена на ваш TON-совместимый кошелек</div>
        <div class="subtitle">Минимальная сумма для отправки {{min}} TON</div>

        <div class="wallet-block">
            <div class="wallet">
                <input type="text" placeholder="Ваш TON адрес" [(ngModel)]="address">
            </div>

            <div class="amount">
                <input type="number" min="{{min}}" max="{{max}}" placeholder="Сумма" [(ngModel)]="amount">
            </div>

            <div class="commission-details">
                <div class="commission">
                    Комиссия сети зависит от выбранного вами провайдера
                </div>
            </div>
        </div>

        <div class="button-wrapper">
            <app-main-button (buttonClick)="onConfirm()" [buttonText]="'ВЫВЕСТИ'"></app-main-button>
        </div>
    </div>
</div>