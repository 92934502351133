<div class="sheet-wrapper">
    <div class="header">
        <div class="close-button" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L11.4142 10L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L10 11.4142L7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929L8.58579 10L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L10 8.58579L12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289Z"
                    fill="#3ED1D2" />
            </svg>
        </div>
    </div>

    <div class="sheet-content">
        <div class="title">
            СМЕНИТЬ ЯЗЫК
        </div>

        <div class="option-button" (click)="onLanguage('en')">
            <div class="name">
                English (en)
            </div>

            <div class="icon">
                @if(language == 'en') {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M19.6987 6.28456C20.0938 6.67043 20.1013 7.30355 19.7155 7.69867L9.94984 17.6987C9.75306 17.9002 9.48044 18.0093 9.19897 17.9994C8.91751 17.9894 8.6533 17.8612 8.47128 17.6463L4.23691 12.6463C3.87998 12.2248 3.9323 11.5938 4.35376 11.2369C4.77521 10.88 5.40621 10.9323 5.76314 11.3537L9.28704 15.5148L18.2846 6.30133C18.6704 5.9062 19.3036 5.89869 19.6987 6.28456Z"
                        fill="white" />
                </svg>
                }
                @else {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L10.3431 4.92893C9.95262 4.53841 9.31946 4.53841 8.92893 4.92893C8.53841 5.31946 8.53841 5.95262 8.92893 6.34315L14.5858 12L8.92893 17.6569C8.53841 18.0474 8.53841 18.6805 8.92893 19.0711C9.31946 19.4616 9.95262 19.4616 10.3431 19.0711L16.7071 12.7071ZM15 13H16V11H15V13Z"
                        fill="white" />
                </svg>
                }
            </div>
        </div>

        <div class="option-button" (click)="onLanguage('ru')">
            <div class="name">
                Русский (ru)
            </div>
        
            <div class="icon">
                @if(language == 'ru') {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M19.6987 6.28456C20.0938 6.67043 20.1013 7.30355 19.7155 7.69867L9.94984 17.6987C9.75306 17.9002 9.48044 18.0093 9.19897 17.9994C8.91751 17.9894 8.6533 17.8612 8.47128 17.6463L4.23691 12.6463C3.87998 12.2248 3.9323 11.5938 4.35376 11.2369C4.77521 10.88 5.40621 10.9323 5.76314 11.3537L9.28704 15.5148L18.2846 6.30133C18.6704 5.9062 19.3036 5.89869 19.6987 6.28456Z"
                        fill="white" />
                </svg>
                }
                @else {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L10.3431 4.92893C9.95262 4.53841 9.31946 4.53841 8.92893 4.92893C8.53841 5.31946 8.53841 5.95262 8.92893 6.34315L14.5858 12L8.92893 17.6569C8.53841 18.0474 8.53841 18.6805 8.92893 19.0711C9.31946 19.4616 9.95262 19.4616 10.3431 19.0711L16.7071 12.7071ZM15 13H16V11H15V13Z"
                        fill="white" />
                </svg>
                }
            </div>
        </div>
    </div>
</div>