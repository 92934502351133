<div class="navigation-bar">
    <div class="wrapper">
        <div (click)="navigateTo('/referrals')" class="item {{ currentRoute == '/referrals' ? 'active': ''}}">
            <div class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13 2H11C9.34315 2 8 3.34315 8 5C8 6.65685 9.34315 8 11 8V11H8C6.34315 11 5 12.3431 5 14V16C3.34315 16 2 17.3431 2 19C2 20.6569 3.34315 22 5 22H7C8.65685 22 10 20.6569 10 19C10 17.3431 8.65685 16 7 16V14C7 13.4477 7.44772 13 8 13H12H16C16.5523 13 17 13.4477 17 14V16C15.3431 16 14 17.3431 14 19C14 20.6569 15.3431 22 17 22H19C20.6569 22 22 20.6569 22 19C22 17.3431 20.6569 16 19 16V14C19 12.3431 17.6569 11 16 11H13V8C14.6569 8 16 6.65685 16 5C16 3.34315 14.6569 2 13 2ZM18 18H17C16.4477 18 16 18.4477 16 19C16 19.5523 16.4477 20 17 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H18ZM5 18H6H7C7.55228 18 8 18.4477 8 19C8 19.5523 7.55228 20 7 20H5C4.44772 20 4 19.5523 4 19C4 18.4477 4.44772 18 5 18ZM12 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H11C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6H12Z"
                        fill="#525252" />
                </svg>
            </div>
            <div class="title">
                Рефералы
            </div>
        </div>

        <div (click)="navigateTo('/tasks')" class="item {{ currentRoute == '/tasks' ? 'active': ''}}">
            <div class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.80952 4.49958C5.80952 4.2238 6.02237 4 6.28571 4H15.6541C15.4484 4.45463 15.3333 4.9632 15.3333 5.5V7H17.2381L15.3333 7V13V13.7778V17.5885C15.3333 18.9204 14.3051 20 13.0367 20C11.7683 20 10.7401 18.9204 10.7401 17.5885V16.0495C10.7401 15.4699 10.2926 15 9.74056 15H5.80952V12.2286V4.49958ZM17.2381 13.7778V17.5885C17.2381 20.0249 15.3571 22 13.0367 22C13.0085 22 12.9804 21.9997 12.9524 21.9991V22H7.71429C4.59677 22 2 19.3488 2 16.0495C2 15.4699 2.44752 15 2.99953 15H3.90476V12.2286V4.49958C3.90476 3.1185 4.9711 2 6.28571 2H18.6667C20.5076 2 22 3.567 22 5.5V7.9505C22 8.53012 21.5525 9 21.0005 9H17.2381V13V13.7778ZM17.2381 7V5.5C17.2381 4.67157 17.8777 4 18.6667 4C19.4556 4 20.0952 4.67157 20.0952 5.5V7H17.2381ZM8.83532 17.5885C8.83532 18.4785 9.08631 19.3069 9.51801 20H7.71429C5.9394 20 4.43201 18.7155 4.01755 17H4.85714H8.83532V17.5885ZM7.71429 10C7.1883 10 6.7619 10.4477 6.7619 11C6.7619 11.5523 7.1883 12 7.71429 12H13.4286C13.9546 12 14.381 11.5523 14.381 11C14.381 10.4477 13.9546 10 13.4286 10H7.71429ZM6.7619 7C6.7619 6.44772 7.1883 6 7.71429 6H13.4286C13.9546 6 14.381 6.44772 14.381 7C14.381 7.55228 13.9546 8 13.4286 8H7.71429C7.1883 8 6.7619 7.55228 6.7619 7Z"
                        fill="#515151" />
                </svg>
            </div>
            <div class="title">
                Задания
            </div>
        </div>

        <div (click)="navigateTo('/home')" class="item {{ currentRoute == '/home' ? 'active': ''}}">
            <div class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2 5.925C2 3.75728 3.75728 2 5.925 2C8.09272 2 9.85 3.75728 9.85 5.925V8H14V6C14 3.79086 15.7909 2 18 2C20.2091 2 22 3.79086 22 6C22 8.20914 20.2091 10 18 10H16V14H18C20.2091 14 22 15.7909 22 18C22 20.2091 20.2091 22 18 22C15.7909 22 14 20.2091 14 18V16H9.85V18.075C9.85 20.2427 8.09272 22 5.925 22C3.75728 22 2 20.2427 2 18.075V18C2 15.7909 3.79086 14 6 14H7.85V10H6C3.79086 10 2 8.20914 2 6V5.925ZM7.85 8V5.925C7.85 4.86185 6.98815 4 5.925 4C4.86185 4 4 4.86185 4 5.925V6C4 7.10457 4.89543 8 6 8H7.85ZM9.85 10V14H14V10H9.85ZM7.85 16H6C4.89543 16 4 16.8954 4 18V18.075C4 19.1381 4.86185 20 5.925 20C6.98815 20 7.85 19.1381 7.85 18.075V16ZM16 16V18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16H16ZM16 8H18C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4C16.8954 4 16 4.89543 16 6V8Z"
                        fill="#525252" />
                </svg>
            </div>
            <div class="title">
                Играть
            </div>
        </div>

        <div (click)="navigateTo('/top')" class="item {{ currentRoute == '/top' ? 'active': ''}}">
            <div class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5 3C5 2.44772 5.44772 2 6 2H18C18.5523 2 19 2.44772 19 3V4H21C21.5523 4 22 4.44772 22 5V9.5C22 10.0523 21.5523 10.5 21 10.5C20.4477 10.5 20 10.0523 20 9.5V6H19V9C19 12.5265 16.3923 15.4439 13 15.9291V20H15C15.5523 20 16 20.4477 16 21C16 21.5523 15.5523 22 15 22H9C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20H11V15.9291C7.60771 15.4439 5 12.5265 5 9V6H4V9.5C4 10.0523 3.55228 10.5 3 10.5C2.44772 10.5 2 10.0523 2 9.5V5C2 4.44772 2.44772 4 3 4H5V3ZM7 5V9C7 11.7614 9.23858 14 12 14C14.7614 14 17 11.7614 17 9V5V4H7V5Z"
                        fill="#525252" />
                </svg>
            </div>
            <div class="title">
                Рейтинг
            </div>
        </div>

        <div (click)="navigateTo('/profile')" class="item {{ currentRoute == '/profile' ? 'active': ''}}">
            <div class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.0952 16C11.3038 16 10.6692 16.0528 10.1278 16.1311C7.89608 16.4541 6.02381 18.6979 6.02381 21C6.02381 21.5523 5.57076 22 5.0119 22C4.45305 22 4 21.5523 4 21C4 18.4069 5.64428 15.8599 8.00623 14.7166C6.19451 13.4487 5.0119 11.3608 5.0119 9C5.0119 5.13401 8.18322 2 12.0952 2C16.0073 2 19.1786 5.13401 19.1786 9C19.1786 11.3608 17.996 13.4487 16.1842 14.7166C18.5462 15.8599 20.1905 18.4069 20.1905 21C20.1905 21.5523 19.7374 22 19.1786 22C18.6197 22 18.1667 21.5523 18.1667 21C18.1667 18.6979 16.2944 16.4541 14.0626 16.1311C13.5212 16.0528 12.8867 16 12.0952 16ZM12.0952 14C9.30094 14 7.03571 11.7614 7.03571 9C7.03571 6.23858 9.30094 4 12.0952 4C14.8895 4 17.1548 6.23858 17.1548 9C17.1548 11.7614 14.8895 14 12.0952 14Z"
                        fill="#525252" />
                </svg>
            </div>
            <div class="title">
                Профиль
            </div>
        </div>
    </div>
</div>