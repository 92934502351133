import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from '../services/session.service';

export const registrationGuard: CanActivateFn = async (route, state) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  const path = route.url.length == 0 ? '' : route.url[0].path;
 
  if (!sessionService.isSignedIn) {
    if(path === '' || path == '/') {
      return true;
    }

    router.navigate(['/']);
    return false;
  }

  if(!sessionService.isRegistered) {
    if (path === 'registration') {
      return true;
    }

    router.navigate(['/registration']);
    return false;
  }

  if (!sessionService.isCharacterSelected) {
    if (path === 'characters') {
      return true;
    }

    router.navigate(['/characters']);
    return false;
  }

  if (path === '') {
    router.navigate(['/home']);
    return false;
  }

  return true;
};
