import { Routes } from '@angular/router';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { CharactersPageComponent } from './pages/characters-page/characters-page.component';
import { EditProfilePageComponent } from './pages/edit-profile-page/edit-profile-page.component';
import { ReferralPageComponent } from './pages/referral-page/referral-page.component';
import { TasksPageComponent } from './pages/tasks-page/tasks-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TopPageComponent } from './pages/top-page/top-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { registrationGuard } from './guards/registration.guard';
import { SingleGamePageComponent } from './pages/single-game-page/single-game-page.component';

export const routes: Routes = [
    { path: '', component: WelcomePageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'welcome', redirectTo: '/', pathMatch: 'full' },

    { path: 'registration', component: RegistrationPageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'characters', component: CharactersPageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'edit-profile', component: EditProfilePageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    
    { path: 'referrals', component: ReferralPageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'tasks', component: TasksPageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'home', component: HomePageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'top', component: TopPageComponent, title: 'Super TON', canActivate: [registrationGuard] },
    { path: 'profile', component: ProfilePageComponent, title: 'Super TON', canActivate: [registrationGuard] },

    { path: 'single-play', component: SingleGamePageComponent, title: 'Super TON', canActivate: [registrationGuard] },

    { path: '**', component: ErrorPageComponent, title: "404 - Super TON" }
];
