"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignalConnections = void 0;
/**
 * Represents a list of connections to a signal for easy disconnect.
 */
class SignalConnections {
  constructor() {
    this.list = [];
  }
  /**
   * Add a connection to the list.
   * @param connection
   */
  add(connection) {
    this.list.push(connection);
  }
  /**
   * Disconnect all connections in the list and empty the list.
   */
  disconnectAll() {
    for (const connection of this.list) {
      connection.disconnect();
    }
    this.list = [];
  }
  /**
   * @returns The number of connections in this list.
   */
  getCount() {
    return this.list.length;
  }
  /**
   * @returns true if this list is empty.
   */
  isEmpty() {
    return this.list.length === 0;
  }
}
exports.SignalConnections = SignalConnections;