"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignalConnections = exports.Signal = exports.CollectorWhile0 = exports.CollectorUntil0 = exports.CollectorLast = exports.CollectorArray = exports.Collector = void 0;
var Collector_1 = require("./Collector");
Object.defineProperty(exports, "Collector", {
  enumerable: true,
  get: function () {
    return Collector_1.Collector;
  }
});
var CollectorArray_1 = require("./CollectorArray");
Object.defineProperty(exports, "CollectorArray", {
  enumerable: true,
  get: function () {
    return CollectorArray_1.CollectorArray;
  }
});
var CollectorLast_1 = require("./CollectorLast");
Object.defineProperty(exports, "CollectorLast", {
  enumerable: true,
  get: function () {
    return CollectorLast_1.CollectorLast;
  }
});
var CollectorUntil0_1 = require("./CollectorUntil0");
Object.defineProperty(exports, "CollectorUntil0", {
  enumerable: true,
  get: function () {
    return CollectorUntil0_1.CollectorUntil0;
  }
});
var CollectorWhile0_1 = require("./CollectorWhile0");
Object.defineProperty(exports, "CollectorWhile0", {
  enumerable: true,
  get: function () {
    return CollectorWhile0_1.CollectorWhile0;
  }
});
var Signal_1 = require("./Signal");
Object.defineProperty(exports, "Signal", {
  enumerable: true,
  get: function () {
    return Signal_1.Signal;
  }
});
var SignalConnections_1 = require("./SignalConnections");
Object.defineProperty(exports, "SignalConnections", {
  enumerable: true,
  get: function () {
    return SignalConnections_1.SignalConnections;
  }
});