import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { LogoComponent } from "../../components/logo/logo.component";
import { MainButtonComponent } from "../../components/main-button/main-button.component";
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
} from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SessionService } from '../../services/session.service';
import { UserData } from '../../models/user.model';

@Component({
  selector: 'app-registration-page',
  standalone: true,
  imports: [
    RouterOutlet, 
    LogoComponent, 
    MainButtonComponent, 
    MatSnackBarLabel, 
    MatSnackBarActions, 
    MatSnackBarAction, 
    ReactiveFormsModule,
  ],
  templateUrl: './registration-page.component.html',
  styleUrl: './registration-page.component.scss'
})
export class RegistrationPageComponent {
  avatarImage: string | null = null;
  registrationForm: FormGroup;

  constructor(private router: Router, private session: SessionService, private fb: FormBuilder, private snackBar: MatSnackBar) {
    this.registrationForm = this.fb.group({
      username: [session.user?.username ?? '', [Validators.required, Validators.minLength(6), Validators.pattern('^[A-Za-z]+$')]],
      image: [null, Validators.required] // Custom validator for image
    });
  }

  onPickImage() {
    const fileInput = document.getElementById('imageInput') as HTMLInputElement;
    fileInput.click();
  }

  onImagePicked(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.avatarImage = e.target.result;
        if (file) {
          this.resizeImage(file, 250, 250, (resizedImage) => {
            const jpegFile = new File([resizedImage], 'resized_image.jpg', { type: 'image/jpeg' });
            this.registrationForm.patchValue({ image: jpegFile });
          });
        }
      };

      reader.readAsDataURL(file);
    }
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number, callback: (resizedFile: Blob) => void) {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        let width = img.width;
        let height = img.height;

        // Calculate the new dimensions while keeping the aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas content to a JPEG Blob
        canvas.toBlob((blob) => {
          if (blob) {
            callback(blob);
          }
        }, 'image/jpeg', 0.7); // Change format to JPEG with quality 0.7
      };
    };

    reader.readAsDataURL(file);
  }


  onRegistrationTap() { 
    if (!this.registrationForm.valid) {
      // Show a snackbar if the form is invalid
      if (this.registrationForm.get('username')?.invalid) {
        this.snackBar.open(
          'Имя пользователя обязательно и должно содержать не менее 8 символов и содержать только английские буквы.',
          'Закрыть'
        );
        return;
      } else if (!this.avatarImage) {
        this.snackBar.open(
          'Вам нужно выбрать фото профиля.', 
          'Закрыть'
        );
        return;
      }
    }

    const username = this.registrationForm.get('username')?.value;
    const image = this.registrationForm.get('image')?.value as File;
    const fileName = image.name; 
    const telegramId = this.session.user?.telegramId!;

    this.session.userService
      .register(telegramId, username, image, fileName)
      .subscribe(
        (userData: UserData | null) => {
          if (userData) {
            this.session.updateUser(userData);
            setTimeout(() => {
              this.router.navigateByUrl('/characters');
            }, 1000);
          } else {
            this.snackBar.open('Registration failed. Please try again.', 'Close', {
              duration: 3000
            });
          }
        },
        (error) => {
          // Handle server or network error
          this.snackBar.open('An error occurred. Please try again later.', 'Close', {
            duration: 3000
          });
        }
      );
  }
}
