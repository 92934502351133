import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { AnimatedSprite, Application, Assets, Container, Graphics, Rectangle, Texture, TilingSprite } from 'pixi.js';
import { Button } from '@pixi/ui';
import { SessionService } from '../../services/session.service';

interface GameSettings {
  playerSpeed: number;
  jumpForce: number;
  layersSpeed: number[];
  speedMultiplier: number;
};

@Component({
  selector: 'app-single-game',
  standalone: true,
  templateUrl: './single-game.component.html',
  styleUrls: ['./single-game.component.scss']
})
export class SingleGameComponent implements AfterViewInit {
  @ViewChild('pixiContainer', { static: true }) pixiContainer!: ElementRef;
  app!: Application;

  private gameSettings: GameSettings;

  constructor(private session: SessionService) {
    this.gameSettings = {
      playerSpeed: 20,
      jumpForce: 20,
      layersSpeed: [0.5, 1, 1.5, 4],
      speedMultiplier: 1,
    };
  }

  ngAfterViewInit(): void {
    this.initializePixiApp();
  }

  async initializePixiApp() {
    this.app = new Application();

    // Initialize the application with custom options
    await this.app.init({
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: 0x1099bb,
    });

    // Append the canvas to the DOM using the new 'canvas' property instead of 'view'
    this.pixiContainer.nativeElement.appendChild(this.app.canvas);

    this.loadAssets();
  }

  async loadAssets(): Promise<void> {
    const assets = [
      { name: 'layer0', url: 'assets/game/background/layer0.png' },
      { name: 'layer1', url: 'assets/game/background/layer1.png' },
      { name: 'layer2', url: 'assets/game/background/layer2.png' },
      { name: 'layer3', url: 'assets/game/background/layer3.png' }
    ];

    const character = this.session.user?.selectedCharacter;
    if (character) {
      Assets.reset();

      character?.gameAnimations.map((animation) => {
        assets.push(
          {
            name: animation.name,
            url: animation.mediaPath.replace('.png', '.json'),
          }
        );
      });
    }

    for (const asset of assets) {
      Assets.add({ alias: asset.name, src: asset.url });
    }

    await Assets.load(assets.map(asset => asset.name));

    this.createParallaxBackground();
    this.createCharacterAnimation();

    const container = new Container();
    const button = new Button(
      new Graphics()
        .fill(0xFFFFFF)
        .rect(0, 0, 100, 50)
    );

    button.onPress.connect(() => console.log('onPress'));

    container.addChild(button.view);
    container.position.set(0.5);
    this.app.stage.addChild(container);
  }

  createCharacterAnimation(): void {
    const runAnimationsSheet = Assets.cache.get('run');
    const jumpAnimationsSheet = Assets.cache.get('jump');

    const character = AnimatedSprite.fromFrames(runAnimationsSheet.data.animations['run']);
    character.animationSpeed = 0.35;
    character.play();

    character.anchor.set(0.5)

    character.x = (window.innerWidth / 4);
    const groundY = window.innerHeight - character.height + 40;
    character.y = groundY;

    this.app.stage.addChild(character);

    let isJumping = false;
    let velocityY = 0;
    const gravity = 0.85;
    const jumpStrength = -25;
    let jumpCount = 0;
    const maxJumps = 2;

    const performJump = () => {
      if (jumpCount < maxJumps) {
        jumpCount++;
        velocityY = jumpStrength;
        character.textures = AnimatedSprite.fromFrames(jumpAnimationsSheet.data.animations['jump']).textures;
        character.play();
        isJumping = true;
      }
    };

    this.app.ticker.add((delta) => {
      if (!isJumping) return;

      velocityY += gravity;

      character.y += velocityY * delta.deltaTime;

      if (character.y >= groundY) {
        character.y = groundY;
        velocityY = 0;
        isJumping = false;
        jumpCount = 0;
        character.textures = AnimatedSprite.fromFrames(runAnimationsSheet.data.animations['run']).textures;
        character.play();
      }
    });

    window.addEventListener('touchstart', performJump);
  }

  createParallaxBackground(): void {
    const layers: TilingSprite[] = [];

    const layerDimensions = [
      { width: 1287, height: 900 },   // Layer 0
      { width: 1287, height: 900 },   // Layer 1
      { width: 1287, height: 208 },   // Layer 2
      { width: 1287, height: 176 }    // Layer 3
    ];

    for (let i = 0; i < 4; i++) {
      const texture = Texture.from(`layer${i}`);

      const tilingSprite = new TilingSprite({
        texture: texture,
        width: texture.width,
        height: texture.height,
      });

      var s = window.innerHeight / texture.height;
      tilingSprite.tileScale.set(s, s);

      if (layerDimensions[i].width > window.innerWidth) {
        tilingSprite.x = (window.innerWidth - layerDimensions[i].width) / 2;
      }

      layers.push(tilingSprite);
      this.app.stage.addChild(tilingSprite);
    }

    this.app.ticker.add((delta) => {
      layers.forEach((layer, index) => {
        layer.tilePosition.x -= this.gameSettings.layersSpeed[index] * delta.deltaTime;
      });
    });
  }
}
