import { Component } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LanguageBottomSheetComponent } from '../language-bottom-sheet/language-bottom-sheet.component';

@Component({
  selector: 'app-settings-bottom-sheet',
  standalone: true,
  imports: [],
  templateUrl: './settings-bottom-sheet.component.html',
  styleUrl: './settings-bottom-sheet.component.scss'
})
export class SettingsBottomSheetComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<SettingsBottomSheetComponent>,
    private router: Router,
    private bottomSheet: MatBottomSheet
  ) { }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  onChangeLanguage() {
    this.close();

    this.bottomSheet.open(
      LanguageBottomSheetComponent,
      { panelClass: 'mat-bottom-sheet-container-dark' }
    );
  }

  onChangeCharacter() {
    this.close();
    this.router.navigateByUrl('/characters');
  }
}
