<div class="sheet-wrapper">
    <div class="header">
        <div class="close-button" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L11.4142 10L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L10 11.4142L7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929L8.58579 10L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L10 8.58579L12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289Z"
                    fill="#3ED1D2" />
            </svg>
        </div>
    </div>

    <div class="sheet-content">
        <app-logo></app-logo>

        <div class="description">
            SuperTon Coin: Ваша Валюта Будущего!
            <br>
            <br>
            Скоро в листинге!
            <br>
            <br>
            SuperTon - это инновационная криптовалюта, предназначенная для интеграции в наши игры
            и за ее пределами.
            <br>
            <br>
            Перспективы SuperTon космические:
            <br>
            <br>
            superton скоро будет доступен на ведущих криптобиржах.
            <br>
            <br>
            Растущий спрос: С увеличением популярности нашей игры
            и криптовалюты, стоимость SuperTon будет расти.
            <br>
            <br>
            Играйте, копите, собирайте
            и сохраняйте SUPERTON
            до листинга.
        </div>
    </div>
</div>