<router-outlet>
    <div class="welcome-page-body">
        <div class="logo-wrapper">
            <app-logo></app-logo>
        </div>

        <div class="title">
            Соревнуйся с игроками по
            всему миру и зарабатывай TON
        </div>

        <div class="welcome-image" style="background-image: url(./assets/images/welcome-page-image.png);">

        </div>
        
        <div class="button-wrapper">
            <app-main-button [buttonText]="'РЕГИСТРАЦИЯ'" (buttonClick)="onRegistrationTap()">>
            </app-main-button>
        </div>
    </div>
</router-outlet>