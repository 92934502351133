import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MainButtonComponent } from "../../../../components/main-button/main-button.component";
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-bottom-sheet',
  standalone: true,
  imports: [MainButtonComponent],
  templateUrl: './welcome-bottom-sheet.component.html',
  styleUrl: './welcome-bottom-sheet.component.scss'
})
export class WelcomeBottomSheetComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<WelcomeBottomSheetComponent>,
    private router: Router
  ) { }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  onRegistrationTap() {
    this.close();
    this.router.navigateByUrl('/registration');
  }
}
