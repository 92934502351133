import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserData } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = 'api.super-ton.org';
  private headers = new HttpHeaders({
    'x-api-key': 'aYsWx3GKpcI2ARFnJlhRwrKDJZrE3QgR',
    'Access-Control-Allow-Origin': '*',
  });

  constructor(private http: HttpClient) { }

  // Method to search for a user by Telegram ID
  search(telegramId: number): Observable<UserData | null> {
    const url = `https://${this.baseUrl}/api/users/search/${telegramId}`;
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      map((response) => {
        if (response == null) return null;
        return UserData.fromMap(response);
      })
    );
  }

  // Method to register a new user
  register(
    telegramId: number,
    username: string,
    image: Blob,
    fileName: string
  ): Observable<UserData | null> {
    const url = `https://${this.baseUrl}/api/users/register`;

    const formData = new FormData();
    formData.append('telegramId', telegramId.toString());
    formData.append('username', username);
    formData.append('profileImage', image, fileName);

    return this.http.post<any>(url, formData, { headers: this.headers }).pipe(
      map((response) => {
        if (response.data === null) return null;
        return UserData.fromMap(response.data);  // Use UserData.fromMap
      })
    );
  }

  // Method to update an existing user
  update(
    telegramId: number,
    username?: string,
    image?: Blob
  ): Observable<UserData | null> {
    const url = `https://${this.baseUrl}/api/users/update`;

    const formData = new FormData();
    formData.append('telegramId', telegramId.toString());
    if (username) formData.append('username', username);
    if (image) formData.append('profileImage', image);

    return this.http.post<any>(url, formData, { headers: this.headers }).pipe(
      map((response) => {
        if (response.data === null) return null;
        return UserData.fromMap(response.data);  // Use UserData.fromMap
      })
    );
  }

  // Method to select a character for a user
  selectCharacter(telegramId: number, characterId: string): Observable<UserData | null> {
    const url = `https://${this.baseUrl}/api/users/selectCharacter`;

    const body = {
      telegramId: telegramId.toString(),
      characterId: characterId,
    };

    return this.http.post<any>(url, body, { headers: this.headers }).pipe(
      map((response) => {
        if (response.data === null) return null;
        return UserData.fromMap(response.data);  // Use UserData.fromMap
      })
    );
  }
}
