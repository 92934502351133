import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SessionService } from '../../services/session.service';
import { UserData } from '../../models/user.model';
import { MainButtonComponent } from "../main-button/main-button.component";
import { SendTransactionRequest, TonConnectUI } from '@tonconnect/ui';
import { tonConnectUI } from '../../app.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-deposit-bottom-sheet',
  standalone: true,
  imports: [MainButtonComponent, FormsModule],
  templateUrl: './deposit-bottom-sheet.component.html',
  styleUrl: './deposit-bottom-sheet.component.scss'
})
export class DepositBottomSheetComponent {
  amount: number | null = null;

  get tonConnect(): TonConnectUI {
    return tonConnectUI;
  }

  get currentUser(): UserData | unknown {
    return this.session.user;
  }

  get min(): number {
    return 0.35;
  }

  get max(): number {
    return 500;
  }

  get tons(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.session.user?.statistic.balance ?? 0));
  }

  get coins(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    });

    return formatter.format((this.session.user?.statistic.coins ?? 0));
  }

  constructor(
    private session: SessionService,
    private bottomSheetRef: MatBottomSheetRef<DepositBottomSheetComponent>,
  ) {
    window.addEventListener('ton-connect-transaction-sent-for-signature', (event: any) => {
      console.log('Transaction init', event.detail);
    });
    window.addEventListener('ton-connect-transaction-signing-failed', (event: any) => {
      this.close();
    });
    window.addEventListener('ton-connect-transaction-signed', (event: any) => {
      this.close();
    });
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  onConfirm() {
    if (this.amount == null || this.amount < 1) return;

    const request: SendTransactionRequest = {
      validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
      messages: [
        {
          address: "0QD6OR5DDb0rT-ZVr27i4oHauKfwze85-xL8pW9MhvuGYXnn",
          amount: (this.amount * 1000000000).toString(),
        }
      ]
    }; 

    this.tonConnect.sendTransaction(request);
  }
}
